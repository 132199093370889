@font-face {
  font-family: "Inter";
  src: url("../src/fonts/inter-medium.woff2"),
    url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZNhiJ-Ek-_EeAmM.woff2)
      format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../src/fonts/inter-regular.woff2"),
    url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZJhiJ-Ek-_EeAmM.woff2)
      format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../src/fonts/inter-semibold.woff2"),
    url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZJhiJ-Ek-_EeAmM.woff2)
      format("woff2");
  font-weight: 600;
  font-style: normal;
}

// Override default variables before the import

$font-family-base: "Inter", system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$enable-negative-margins: true;

$unblack: #202020;
$unoffwhite: #eff2f6;
$unlightgrey: #dfe4ea;
$ungrey: #445150;
$undarkgrey: #2f2f2f;
$uncream: #d8cfeb;
$unbeige: #fafafa;
$ungreen: #5ac8a7;
$unlightpurple: #e7e2f0;
$unpurple: #7e60bc;
$unfadedpurple: #7e60bc;
$undarkpurple: #594485;
$unorange: #ffa24c;
$unred: #b00b1e;

$primary: $unpurple;
$secondary: $ungrey;
$green: $ungreen;
$info: $ungreen;
$warning: $unorange;
$danger: $unred;
$light: $unlightgrey;
$dark: $undarkgrey;
$gray-700: $ungrey;
$light-text-emphasis-dark: $unbeige;
$dark-text-emphasis-dark: $unlightgrey;

@import "~bootstrap/scss/bootstrap.scss";

:root {
  --bs-primary-bg-subtle: #ebe3f722;
  --bs-light-bg-subtle: #dfe4ea77;
  --star-bg-yellow: #fff0bb;
  --star-fg-orange: #ffac33;
  --success-green: #5ac8a7;
  --danger-red: #b00b1e;
}
@keyframes zoom {
  0% {
    animation-timing-function: ease-in;
    scale: 1;
  }
  100% {
    animation-timing-function: ease-in;
    scale: 2;
  }
}
@keyframes show {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
  }
  100% {
    animation-timing-function: ease-in;
    opacity: 1;
  }
}
h1,
.row > div > h2,
h5 {
  color: $undarkpurple;
}
#menuPopover {
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
}
.fc-darkhighlight {
  color: $undarkpurple;
}
.fs-7 {
  font-size: 0.8rem;
}
.flair {
  @extend .link-offset-2;
  @extend .link-offset-3-hover;
  @extend .link-underline;
  @extend .link-underline-opacity-0;
  @extend .link-underline-opacity-25-hover;
}
.translucent {
  opacity: 0.6;
}
.small-text {
  font-size: 0.9em;
}
.smaller-text {
  font-size: 0.8em;
}
.caps-header {
  text-transform: uppercase;
  font-weight: bold;
}
.large-text {
  font-size: 1.7rem;
}
.light-text {
  color: $unbeige;
}
.dark-text {
  color: $undarkgrey;
}
.activatable-20 {
  cursor: pointer;
}
img.rec-thumbnail {
  max-height: 100px;
}
.activatable-20:hover {
  animation: zoom 0.2s ease-in 0s 0.2 normal forwards;
}
.activatable-50 {
  cursor: pointer;
}
.activatable-50:hover {
  animation: zoom 0.2s ease-in 0s 0.5 normal forwards;
}
.activatable-danger {
  color: var(--bs-danger-border-subtle);
}
.activatable-danger:hover {
  color: var(--bs-danger);
}
.activatable-success {
  color: var(--bs-success-border-subtle);
}
.activatable-success:hover {
  color: var(--bs-success);
}
.disabled-link {
  color: "#202020";
  font-weight: 500;
  opacity: 0.5;
}
.disabled-link:hover {
  text-decoration: line-through;
}
.inline-block {
  display: inline-block;
}
.horizontal-scroll-flex {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  * {
    flex: 0 0 auto;
  }
}
.link-button {
  color: $unblack;
}
.request-label {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.9em;
  line-height: 0.9em;
  padding: 0.5em;
  opacity: 1;
  border-radius: 5px;
  margin: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
  box-shadow: $box-shadow-sm;
}
.request-incomplete {
  background-color: $unfadedpurple;
  color: $unoffwhite;
}
.request-finished {
  background-color: $success;
  color: $unoffwhite;
}
.thumbnail-container {
  max-height: 14em;
  width: 100%;
}
.request-thumbnail {
  max-height: 100%;
}
.request-card-lede {
  // height: 2.5em !important;
  font-weight: 500;
  background-color: $unbeige;
  border-radius: 2px;
  p {
    text-align: left;
    font-size: 1em;
    margin: 0;
    padding: 0.3em;
    a {
      text-decoration: none;
      color: $unblack;
    }
  }
}
.Popover .flair {
  color: $unblack;
  font-weight: 500;
}
.footer-nav-link {
  @extend .flair;
}
.btn-primary {
  a {
    color: $white;
    text-decoration: none;
    display: inline-block;
    margin: 0 0.5em;
  }
}
.Divider {
  width: 100%;
  height: 1px;
  background-color: #dfe4ea;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.dark .Divider {
  background-color: #1d2c6b;
}
.vertical.Divider {
  position: relative;
  display: inline-block;
  width: 1px;
  height: 100%;
  margin: 0 8px;
}
a.img-link {
  display: block;
  border: 2px solid transparent;
}
a.img-link:hover {
  display: block;
  border: 2px solid $unpurple;
}
